export default [
  {
    country: "Greece",
    key: "greece",
    hasBoutique: true,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Cyprus",
    key: "cyprus",
    hasBoutique: true,
    url: "https://www.togas.com/cy/",
  },
  {
    country: "Austria",
    key: "austria",
    hasBoutique: true,
    url: "https://www.togas.com/int/en/store-locator/at/",
  },
  {
    country: "Spain",
    key: "spain",
    hasBoutique: true,
    url: "https://www.togas.com/int/",
  },
  {
    country: "USA",
    key: "usa",
    currency: "$",
    hasBoutique: true,
    url: "https://www.togas.com/us/",
  },
  {
    country: "United Arab Emirates",
    key: "united_arab_emirates",
    hasBoutique: true,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Saudi Arabia",
    key: "saudi_arabia",
    hasBoutique: true,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Côte d'Ivoire",
    key: "ivory_coast",
    hasBoutique: true,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Montenegro",
    key: "montenegro",
    hasBoutique: true,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Serbia",
    key: "serbia",
    hasBoutique: true,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Armenia",
    key: "armenia",
    hasBoutique: true,
    url: "https://www.togas.com/int/en/store-locator/am/",
  },
  {
    country: "Azerbaijan",
    key: "azerbaijan",
    hasBoutique: true,
    url: "https://www.togas.com/int/en/store-locator/az/",
  },
  {
    country: "Kazakhstan",
    key: "kazakhstan",
    hasBoutique: true,
    url: "https://www.togas.com/kz/",
  },
  {
    country: "Uzbekistan",
    key: "uzbekistan",
    hasBoutique: true,
    url: "https://www.togas.com/ru/",
  },
  {
    country: "Kirgyzstan",
    key: "kirgyzstan",
    hasBoutique: true,
    url: "https://www.togas.com/ru/",
  },
  {
    country: "Russia",
    key: "russia",
    hasBoutique: true,
    url: "https://www.togas.com/ru/",
  },
  {
    country: "Belarus",
    key: "belarus",
    hasBoutique: true,
    url: "https://www.togas.com/ru/",
  },
  {
    country: "Latvia",
    key: "latvia",
    hasBoutique: true,
    url: "https://www.togas.com/lv/",
  },
  {
    country: "Albania",
    key: "albania",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Algeria",
    key: "algeria",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "American Samoa",
    key: "american_samoa",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Angola",
    key: "angola",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Anguilla",
    key: "anguilla",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Andorra",
    key: "andorra",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Antigua And Barbuda",
    key: "antigua_and_barbuda",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Argentina",
    key: "argentina",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Aruba",
    key: "aruba",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Australia",
    key: "australia",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Bahamas",
    key: "bahamas",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Bahrain",
    key: "bahrain",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Bangladesh",
    key: "bangladesh",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Barbados",
    key: "barbados",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Belize",
    key: "belize",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Belgium",
    key: "belgium",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Bosnia and Herzegovina",
    key: "bosnia_and_herzegovina",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Benin",
    key: "benin",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Bermuda",
    key: "bermuda",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Bhutan",
    key: "bhutan",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Bolivia",
    key: "bolivia",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Botswana",
    key: "botswana",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Brazil",
    key: "brazil",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Brunei Darussalam",
    key: "brunei",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Bulgaria",
    key: "bulgaria",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Burkina Faso",
    key: "burkina_faso",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Burundi",
    key: "burundi",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Cambodia",
    key: "cambodia",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Cameroon",
    key: "cameroon",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Canada",
    key: "canada",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Cape Verde",
    key: "cape_verde",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Cayman Islands",
    key: "cayman_islands",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Central african Republic",
    key: "central_african_republic",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Chad",
    key: "chad",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Chile",
    key: "chile",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "China",
    key: "china",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Colombia",
    key: "colombia",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Comoros",
    key: "comoros",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Congo",
    key: "congo",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Costa Rica",
    key: "costa_rica",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Croatia",
    key: "croatia",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Cuba",
    key: "cuba",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Czech Republic",
    key: "czech_republick",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Denmark",
    key: "denmark",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Djibouti",
    key: "djibouti",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Dominican Republic",
    key: "dominican_republic",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Ecuador",
    key: "ecuador",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Egypt",
    key: "egypt",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "El Salvador",
    key: "el_salvador",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Equatorial Guinea",
    key: "equatorial_guinea",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Eritrea",
    key: "eritrea",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Estonia",
    key: "estonia",
    hasBoutique: false,
    url: "https://www.togas.com/lv/",
  },
  {
    country: "Ethiopia",
    key: "ethiopia",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Falkland Islands (Malvinas)",
    key: "falkland_islands",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Fiji",
    key: "fiji",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Finland",
    key: "finland",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "France",
    key: "france",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "French Guiana",
    key: "french_guiana",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Gabon",
    key: "gabon",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Gambia",
    key: "gambia",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Georgia",
    key: "georgia",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Germany",
    key: "germany",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Ghana",
    key: "ghana",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Gibraltar",
    key: "gibraltar",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Greenland",
    key: "greenland",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Grenada",
    key: "grenada",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Guadeloupe",
    key: "guadeloupe",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Guam",
    key: "guam",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Guatemala",
    key: "guatemala",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Guernsey",
    key: "guernsey",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Guinea",
    key: "guinea",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Guinea-Bissau",
    key: "guinea_bissau",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Guyana",
    key: "guyana",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Haiti",
    key: "haiti",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Honduras",
    key: "honduras",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Hong Kong",
    key: "hong_kong",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Hungary",
    key: "hungary",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Iceland",
    key: "iceland",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "India",
    key: "india",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Indonesia",
    key: "indonesia",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Iran",
    key: "iran",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Iraq",
    key: "iraq",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Ireland",
    key: "ireland",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Israel",
    key: "israel",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Isle of Man",
    key: "isle_of_man",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Italy",
    key: "italy",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Jamaica",
    key: "jamaica",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Japan",
    key: "japan",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Jersey",
    key: "jersey",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Jordan",
    key: "jordan",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Kenya",
    key: "kenya",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Kuwait",
    key: "kuwait",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Lebanon",
    key: "lebanon",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Lesotho",
    key: "lesotho",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Liberia",
    key: "liberia",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Libyan Arab Jamahiriya",
    key: "libyan_arab_jamahiriya",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Liechtenstein",
    key: "liechtenstein",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Lithuania",
    key: "lithuania",
    hasBoutique: false,
    url: "https://www.togas.com/lv/",
  },
  {
    country: "Luxembourg",
    key: "luxembourg",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Macao",
    key: "macao",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Macedonia",
    key: "macedonia",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Madagascar",
    key: "madagascar",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Malawi",
    key: "malawi",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Malaysia",
    key: "malaysia",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Maldives",
    key: "maldives",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Mali",
    key: "mali",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Malta",
    key: "malta",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Marshall Islands",
    key: "marshall_islands",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Martinique",
    key: "martinique",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Mauritania",
    key: "mauritania",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Mayotte",
    key: "mayotte",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Mexico",
    key: "mexico",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Moldova",
    key: "moldova",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Monaco",
    key: "monaco",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Mongolia",
    key: "mongolia",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Montserrat",
    key: "montserrat",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Morocco",
    key: "morocco",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Mozambique",
    key: "mozambique",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Namibia",
    key: "namibia",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Nepal",
    key: "nepal",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Netherlands",
    key: "netherlands",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "New Caledonia",
    key: "new_caledonia",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "New Zealand",
    key: "new_zealand",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Nicaragua",
    key: "nicaragua",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Niger",
    key: "niger",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Nigeria",
    key: "nigeria",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Norfolk Island",
    key: "norfolk_island",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Norway",
    key: "norway",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Oman",
    key: "oman",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Pakistan",
    key: "pakistan",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Panama",
    key: "panama",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Papua New Guinea",
    key: "papua_new_guinea",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Paraguay",
    key: "paraguay",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Peru",
    key: "peru",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Philippines",
    key: "philippines",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Poland",
    key: "poland",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Portugal",
    key: "portugal",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Puerto Rico",
    key: "puerto_rico",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Qatar",
    key: "qatar",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Romania",
    key: "romania",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Rwanda",
    key: "rwanda",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Saint Barthelemy",
    key: "saint_barthelemy",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Saint Lucia",
    key: "saint_lucia",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Saint Martin",
    key: "saint_martin",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Samoa",
    key: "samoa",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "San Marino",
    key: "san_marino",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Senegal",
    key: "senegal",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Seychelles",
    key: "seychelles",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Sierra Leone",
    key: "sierra_leone",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Singapore",
    key: "singapore",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Slovenia",
    key: "slovenia",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Solomon Islands",
    key: "solomon_islands",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Somalia",
    key: "somalia",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "South africa",
    key: "south_africa",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "South Korea",
    key: "south_korea",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Sri Lanka",
    key: "sri_lanka",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Sudan",
    key: "sudan",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Suriname",
    key: "suriname",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Syrian Arab Republic",
    key: "syrian_arab_republic",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Sweden",
    key: "sweden",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Switzerland",
    key: "switzerland",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Taiwan",
    key: "taiwan",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Tajikistan",
    key: "tajikistan",
    hasBoutique: false,
    url: "https://www.togas.com/ru/",
  },
  {
    country: "Tanzania",
    key: "tanzania",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Thailand",
    key: "thailand",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Togo",
    key: "togo",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Trinidad And Tobago",
    key: "trinidad_and_tobago",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Tunisia",
    key: "tunisia",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Turkey",
    key: "turkey",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Turkmenistan",
    key: "turkmenistan",
    hasBoutique: false,
    url: "https://www.togas.com/ru/",
  },
  {
    country: "Ukraine",
    key: "ukraine",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Uganda",
    key: "uganda",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "United Kingdom",
    key: "united_kingdom",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Uruguay",
    key: "uruguay",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Vatican City State",
    key: "vatican",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Vanuatu",
    key: "vanuatu",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Venezuela",
    key: "venezuela",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Vietnam",
    key: "vietnam",
    hasBoutique: false,
    url: "https://www.togas.com/int/",
  },
  {
    country: "Virgin Islands, British",
    key: "virgin_islands_br",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Virgin Islands, U.S.",
    key: "virgin_islands_us",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Wallis And Futuna",
    key: "wallis_and_futuna",
    hasBoutique: false,
    url: "https://www.togas.com/us/",
  },
  {
    country: "Western Sahara",
    key: "western_sahara",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Yemen",
    key: "yemen",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Zaire",
    key: "zaire",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Zambia",
    key: "zambia",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "Zimbabwe",
    key: "zimbabwe",
    hasBoutique: false,
    url: "https://www.togas.com/ae/",
  },
  {
    country: "International",
    key: "globe",
    hasBoutique: true,
    url: "https://www.togas.com/int/",
  },
];
