
























































import {
  computed,
  defineComponent, onMounted, PropType,
} from '@nuxtjs/composition-api';
import { SfButton, SfIcon, SfInput, SfImage, SfList } from '~/components';
import { useStore } from "../../../../composables/useStore";
import { AvailableStores, useConfig } from "~/composables";
import countriesList from "~/modules/countries/countries-list";

export default defineComponent({
  name: 'LocationSelector',
  components: {
    SfButton,
    SfIcon,
    SfInput,
    SfImage,
    SfList,
  },
  data() {
    return {
      filterValue: '',
      filteredCountries: countriesList,
      isCountriesListFiltered: false,
      selectedCountry: this.$cookies.get('vsf-selected-country') || ''
    }
  },
  methods: {
    filterCountries(filterValue) {
      if (!!filterValue) {
        this.filteredCountries = countriesList.filter((countryObject) => {
          return countryObject.country.toLowerCase().includes(filterValue.toLowerCase())
        });
        this.isCountriesListFiltered = true
      } else {
        this.clearFilterCountries();
      }
    },
    clearFilterCountries () {
      this.filterValue = '';
      this.filteredCountries = countriesList;
      this.isCountriesListFiltered = false;
    },
    setCountry (country) {
      this.$cookies.set("vsf-selected-country", country);
    }
  },
  setup() {
    const {
      stores,
      change: changeStore,
      load: loadStores,
    } = useStore();
    const { config } = useConfig();

    const availableStores = computed<AvailableStores>(() => stores.value ?? []);

    onMounted(() => {
      if (stores.value && stores.value?.length) return;
      loadStores();
    });

    return {
      availableStores,
      changeStore,
      storeConfig: config,
    };
  },
});
